.clickable {
  cursor: pointer;
}
.icon-row {
  width: 2em;
}
.icon-folder {
  width: 1em;
}
.icon-file {
  width: 0.75em;
}
.image-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
