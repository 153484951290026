/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Work+Sans:wght@400;500;600&display=swap');
@import url('https://fonts.google.com/specimen/Plus+Jakarta+Sans?query=plus'); */
/* 
@font-face {
  font-family: Inter;
  src:"./Inter/Inter-VariableFont_slnt,wght.ttf";
} */
@font-face {
  font-family: "Inter";
  src: local("Inter"),
   url("./Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}